<template>
  <div>
    <!-- <h4>Perfil</h4>

    <hr /> -->
    <!-- <div class="row">
    <div class="col-lg-3 text-center">-->
        <div class="text-center">
        <b-avatar :src="perfil.foto" variant size="5rem" class="shadow"></b-avatar>
</div>
   
    <!--</div>
       <div class="col-lg-9">
        <div class="row"> 
    <div class="col-lg-4">-->
    <div class="form-group">
      <label>Nome</label>
      <input type="text" class="form-control" :value="perfil.nome || 'Não informado'" readonly />
    </div>
    <!-- </div>
    <div class="col-lg-4">-->
    <div class="form-group">
      <label>E-mail</label>
      <input type="text" class="form-control" :value="perfil.email || 'Não informado'" readonly />
    </div>
    <!-- </div>
    <div class="col-lg-4">-->
    <div class="form-group">
      <label>Telefone</label>
      <input
        type="text"
        class="form-control"
        :value="formatacaoTelefone(perfil.celular) || 'Não informado'"
        readonly
      />
    </div>
    <!-- </div>
    </div>-->

    <div class="alert alert-info">
      Para alterar as informações, faça login na plataforma Bike Registrada e acesse a sessão
      <a
        href="https://bikeregistrada.com.br/dashboard/profile"
        target="_blank"
      >"Meus Dados"</a>.
    </div>
    <!-- </div>
    </div>-->
  </div>
</template>

<script>
import auth from "../../core/auth";
import formatacaoTelefone from "../../helpers/formatacaoTelefone";

export default {
  data() {
    return {
      formatacaoTelefone,

      perfil: {},
    };
  },
  created() {
    this.populateUserInfo();
  },

  methods: {
    populateUserInfo() {
      this.perfil = auth.getUserInfo();
    },
  },
};
</script>