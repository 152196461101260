<template>
  <div class="container-minha-conta text-left px-2 pb-5">
    <h2 class="pt-3">Minha conta</h2>

    <div class="card mt-3">
      <div class="card-body text-left">
        <BlocoPerfil />
      </div>
    </div>
  </div>
</template>

<script>
import BlocoPerfil from "../../components/Dashboard/BlocoPerfil";

export default {
  components: {
    BlocoPerfil,
  },
};
</script>

<style scoped>
.container-minha-conta{
    width: 100%;
    max-width: 570px;
    margin: 0 auto;
}
</style>